// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ChatWindow from './components/ChatWindow';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ConfirmSignUp from './pages/ConfirmSignUp';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './UserContext';
import SetUserContext from './SetUserContext';
import { WebSocketProvider } from './services/WebSocketProvider';
import './App.css';

const AppContent = () => {
  const location = useLocation();
  const isAuthPage = ['/login', '/signup', '/confirm-signup', '/logout'].includes(location.pathname);

  if (isAuthPage) {
    return (
      <div className="auth-page">
        <div className="logo"></div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confirm-signup" element={<ConfirmSignUp />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <div className="main-content">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SetUserContext>
                  <Sidebar />
                  <ChatWindow />
                </SetUserContext>
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts/:accountId/users/:userId"
            element={
              <ProtectedRoute>
                <SetUserContext>
                  <Sidebar />
                  <ChatWindow />
                </SetUserContext>
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts/:accountId/users/:userId/chats/:chatId"
            element={
              <ProtectedRoute>
                <SetUserContext>
                  <Sidebar />
                  <ChatWindow />
                </SetUserContext>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <UserProvider>
        <WebSocketProvider>
          <AppContent />
        </WebSocketProvider>
      </UserProvider>
    </Router>
  );
};

export default App;